import React from "react";
import "./lokasi.scss";
import mapsimage from "../../media/Maps_Desktop.png";
import mobilemaps from "../../media/Maps_Mobile.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div id="location" className="lokasi">
      <div className="judul">Location</div>
      <div className="maps-lokasi">
        <img
          id="location"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
      <div className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke IPK School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke BPK Penabur
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Al-Azhar BSD School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Atmajaya University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Pelita Harapan University
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Bethsaida Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;7 Menit ke Eka Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;4 Menit ke RS Columbia Asia BSD Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;15 Menit ke Hermina Hospital
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Entertainment
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke Grand Lucky
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;2 Menit ke QBIG BSD
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 menit ke Eastvara BSD
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;5 Menit ke The Breeze
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;

import Wynyard71 from '../../../media/Wynyard 1/Wynyard 7 (1).webp'
import Wynyard72 from '../../../media/Wynyard 1/Wynyard 7 (2).webp'
import Wynyard73 from '../../../media/Wynyard 1/Wynyard 7 (3).webp'
import Wynyard74 from '../../../media/Wynyard 1/Wynyard 7 (4).webp'
import Wynyard75 from '../../../media/Wynyard 1/Wynyard 7 (5).webp'
import Wynyard76 from '../../../media/Wynyard 1/Wynyard 7 (6).webp'
import Wynyard77 from '../../../media/Wynyard 1/Wynyard 7 (7).webp'
import Wynyard78 from '../../../media/Wynyard 1/Wynyard 7 (8).webp'
import Wynyard79 from '../../../media/Wynyard 1/Wynyard 7 (9).webp'
import Wynyard710 from '../../../media/Wynyard 1/Wynyard 7 (10).webp'

const Wynyard7images =[
Wynyard71,
Wynyard72,
Wynyard73,
Wynyard74,
Wynyard75,
Wynyard76,
Wynyard77,
Wynyard78,
Wynyard79,
Wynyard710,
]

export default Wynyard7images;
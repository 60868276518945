import React from "react";
import "./rumah.scss";
import Produk1 from "./Produk1/Produk1.jsx";
import logowelton from "../../media/logo-welton.png";

const rumah = () => {
  return (
    <div id="welton" className="rumah">
      <div className="logowelton">
        <img className="gambarwelton" src={logowelton} alt="logo welton" />
      </div>
      <div className="containerproduk1">
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
    </div>
  );
};

export default rumah;

import Wynyard91 from '../../../media/Wynyard 1/Wynyard 9 (1).webp'
import Wynyard92 from '../../../media/Wynyard 1/Wynyard 9 (2).webp'
import Wynyard93 from '../../../media/Wynyard 1/Wynyard 9 (3).webp'
import Wynyard94 from '../../../media/Wynyard 1/Wynyard 9 (4).webp'
import Wynyard95 from '../../../media/Wynyard 1/Wynyard 9 (5).webp'
import Wynyard96 from '../../../media/Wynyard 1/Wynyard 9 (6).webp'
import Wynyard97 from '../../../media/Wynyard 1/Wynyard 9 (7).webp'
import Wynyard98 from '../../../media/Wynyard 1/Wynyard 9 (8).webp'
import Wynyard99 from '../../../media/Wynyard 1/Wynyard 9 (9).webp'
import Wynyard910 from '../../../media/Wynyard 1/Wynyard 9 (10).webp'

const Wynyard9images =[
Wynyard91,
Wynyard92,
Wynyard93,
Wynyard94,
Wynyard95,
Wynyard96,
Wynyard97,
Wynyard98,
Wynyard99,
Wynyard910,
]

export default Wynyard9images;
import React from "react";
import "./newlaunching1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Wynyard7images from "./Wynyard7.js";
import Wynyard8images from "./Wynyard8.js";
import Wynyard9images from "./Wynyard9.js";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Muzi = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Muzi)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Dento = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Dento)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Yuga = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Yuga)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {Wynyard7images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Muzi</div>
              <span>Free DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">18</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(75, 75, 75)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 98m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faHouse} />
            <span> : 160m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faBed} />
            <span>: 4+1+1</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faShower} />
            <span>: 3+1+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Muzi} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Wynyard8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Dento</div>
              <span>Free DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">25</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(75, 75, 75)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 144m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faHouse} />
            <span> : 205m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faBed} />
            <span>: 5+1</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faShower} />
            <span>: 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Dento} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Wynyard9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Yuga</div>
              <span>Free DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">33</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(75, 75, 75)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 180m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faHouse} />
            <span> : 285m²</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faBed} />
            <span>: 5+1</span>
            <FontAwesomeIcon color="rgb(75, 75, 75)" icon={faShower} />
            <span>: 5+1+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Yuga} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;

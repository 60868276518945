import welton71 from '../../../media/Wellton/t7 (1).webp'
import welton72 from '../../../media/Wellton/t7 (2).webp'
import welton73 from '../../../media/Wellton/t7 (3).webp'
import welton74 from '../../../media/Wellton/t7 (4).webp'
import welton75 from '../../../media/Wellton/t7 (5).webp'
import welton76 from '../../../media/Wellton/t7 (6).webp'
import welton77 from '../../../media/Wellton/t7 (7).webp'
import welton78 from '../../../media/Wellton/t7 (8).webp'
import welton79 from '../../../media/Wellton/t7 (9).webp'
import welton710 from '../../../media/Wellton/t7 (10).webp'
import welton711 from '../../../media/Wellton/t7 (11).webp'
import welton712 from '../../../media/Wellton/t7 (12).webp'

const Welton7images =[
welton71,
welton72,
welton73,
welton74,
welton75,
welton76,
welton77,
welton78,
welton79,
welton710,
welton711,
welton712,
]

export default Welton7images;
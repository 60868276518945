import welton81 from '../../../media/Wellton/t8 (1).webp'
import welton82 from '../../../media/Wellton/t8 (2).webp'
import welton83 from '../../../media/Wellton/t8 (3).webp'
import welton84 from '../../../media/Wellton/t8 (4).webp'
import welton85 from '../../../media/Wellton/t8 (5).webp'
import welton86 from '../../../media/Wellton/t8 (6).webp'
import welton87 from '../../../media/Wellton/t8 (7).webp'
import welton88 from '../../../media/Wellton/t8 (8).webp'
import welton89 from '../../../media/Wellton/t8 (9).webp'
import welton810 from '../../../media/Wellton/t8 (10).webp'
import welton811 from '../../../media/Wellton/t8 (11).webp'
import welton812 from '../../../media/Wellton/t8 (12).webp'
import welton813 from '../../../media/Wellton/t8 (13).webp'

const Welton8images =[
welton81,
welton82,
welton83,
welton84,
welton85,
welton86,
welton87,
welton88,
welton89,
welton810,
welton811,
welton812,
welton813,
]

export default Welton8images;
import welton91 from '../../../media/Wellton/t9 (1).webp'
import welton92 from '../../../media/Wellton/t9 (2).webp'
import welton93 from '../../../media/Wellton/t9 (3).webp'
import welton94 from '../../../media/Wellton/t9 (4).webp'
import welton95 from '../../../media/Wellton/t9 (5).webp'
import welton96 from '../../../media/Wellton/t9 (6).webp'
import welton97 from '../../../media/Wellton/t9 (7).webp'
import welton98 from '../../../media/Wellton/t9 (8).webp'
import welton99 from '../../../media/Wellton/t9 (9).webp'


const Welton9images =[
welton91,
welton92,
welton93,
welton94,
welton95,
welton96,
welton97,
welton98,
welton99,
]

export default Welton9images;
import Wynyard81 from '../../../media/Wynyard 1/Wynyard 8 (1).webp'
import Wynyard82 from '../../../media/Wynyard 1/Wynyard 8 (2).webp'
import Wynyard83 from '../../../media/Wynyard 1/Wynyard 8 (3).webp'
import Wynyard84 from '../../../media/Wynyard 1/Wynyard 8 (4).webp'
import Wynyard85 from '../../../media/Wynyard 1/Wynyard 8 (5).webp'
import Wynyard86 from '../../../media/Wynyard 1/Wynyard 8 (6).webp'
import Wynyard87 from '../../../media/Wynyard 1/Wynyard 8 (7).webp'
import Wynyard88 from '../../../media/Wynyard 1/Wynyard 8 (8).webp'
import Wynyard89 from '../../../media/Wynyard 1/Wynyard 8 (9).webp'


const Wynyard8images =[
Wynyard81,
Wynyard82,
Wynyard83,
Wynyard84,
Wynyard85,
Wynyard86,
Wynyard87,
Wynyard88,
Wynyard89,
]

export default Wynyard8images;
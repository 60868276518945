import React from "react";
import "./newlaunching.scss";
import Newlaunching1 from "./newlaunching1/newlaunching1.jsx";
import logowynyard from "../../media/Logo-Whnyard.png";
import penawaran from "../../media/hardsell_newlaunch.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const rumah = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287872005300&text=Halo+Felice%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Hiera%20(Promo%20New)%20https://www.bsdcity-hiera.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="newlaunch" className="rumah1">
      <div className="judulnewlaunch">
        <div className="container-newlaunch">
          <div className="logowynyard">
            <h1>NEW LAUNCHING</h1>
            <img
              className="gambarwynyard"
              src={logowynyard}
              alt="logo wynyard"
            />
          </div>
        </div>
        <div className=" containerPenawaran">
          <div className="containercontent">
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Free DP
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Free Kitchen Set
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Free Solar Panel
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Free Canopy
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Full Marble Floor*
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Free Sanitary by GROHE
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faCheckDouble} />
                <span style={{color: "rgb(70, 70, 70)"}}>
                  &nbsp;&nbsp;Inner Garden
                </span>
              </div>
            </div>
            <div className="disclaimer">*Disclaimer</div>
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
          <div className="containergambar">
            <img className="penawarangambar" src={penawaran} />
          </div>
        </div>
      </div>
      <div className="rumah-card">
        <Newlaunching1 />
      </div>
    </div>
  );
};

export default rumah;

import Future1 from '../../../src/media/Future of Hiera/Future (1).webp'
import Future2 from '../../../src/media/Future of Hiera/Future (2).webp'
import Future3 from '../../../src/media/Future of Hiera/Future (3).webp'
import Future4 from '../../../src/media/Future of Hiera/Future (4).webp'
import Future5 from '../../../src/media/Future of Hiera/Future (5).webp'
import Future6 from '../../../src/media/Future of Hiera/Future (6).webp'
import Future7 from '../../../src/media/Future of Hiera/Future (7).webp'
import Future8 from '../../../src/media/Future of Hiera/Future (8).webp'



const Futureimages =[
Future1,Future2,Future3,Future4,Future5,Future6,Future7,Future8,
]

export default Futureimages;